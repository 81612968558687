import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

// Components
import {connect} from 'react-redux';
import {withRouter} from '../common/WithRouter/WithRouter';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  getAntibodiesConceptSelector,
  getAntibodiesErrorSelector,
  getAntibodiesLoadingSelector,
  getAntibodiesPatentsCountSelector,
  getAntibodiesWiposCountSelector,
} from './store/selectors';
import {loadAntibodiesDataAction} from './store/reducer';
import Loader from '../common/Loader/Loader';

import Error from '../common/Error/Error';
import AntibodiesPlabDabTable from './AntibodiesPlabDabTable';
import AntibodiesWipoTable from './AntibodiesWipoTable';
import {getAntibodiesParams} from './Utils';
// Styles
import './styles.scss';
import {RELATIVE_PATH} from '../../constantsCommon';
import {RiSearchLine} from 'react-icons/ri';
import ConceptSearchModal from '../Concept/ConceptSearchModal/ConceptSearchModal';
import {setActiveCategoriesAction, setUseCustomSemTypesAction} from '../Concept/ConceptSearchModal/store/actions';
import {PortalActions} from '../Search/enums';
import classNames from 'classnames';

const propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  closeCb: PropTypes.func,
  loadAntibodiesData: PropTypes.func,
  savedSuccessfully: PropTypes.bool,
  concept: PropTypes.object,
  setActiveCategories: PropTypes.func,
  setUseCustomSemTypes: PropTypes.func,
  patentsCount: PropTypes.number,
  wiposCount: PropTypes.number,
};

const AntibodiesPage = (props) => {
  const {
    loadAntibodiesData,
    loading,
    error,
    concept,
    setActiveCategories,
    setUseCustomSemTypes,
    patentsCount,
    wiposCount,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [showSearchConceptModal, setShowSearchConceptModal] = useState({ show: false, isNewSearch: true });
  const [isActiveTabPlabdab, setIsActiveTabPlabdab] = useState(true);

  useEffect(() => {
    const params = getAntibodiesParams(location.search);
    if (params.id || params.name) {
      loadAntibodiesData({id: params.id, name: params.name});
    }
  }, [location]);

  const targetTop = useRef(null);
  const targetWipos = useRef(null);

  // Function to scroll to any target element by ref
  const scrollToElement = (e, targetElementRef, hash) => {
    e.preventDefault();
    if (targetElementRef.current) {
      targetElementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // Optionally update the URL hash without reloading
    window.history.replaceState(null, null, `${location.pathname}${location.search}${hash}`);
  };

  useEffect(() => {
    // Automatically scroll to the target element if the hash matches on page load or change
    if (location.hash === '#antibodies-page-top' && targetTop.current) {
      targetTop.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#antibodies-page-wipos' && targetWipos.current) {
      targetWipos.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.hash]);

  function openAntibodyFinder() {
    setActiveCategories(['T10001', 'T116', 'T109', 'T005', 'T129', 'T104']);
    setUseCustomSemTypes(true);
    toggleSearch();
  }

  function toggleSearch(isNewSearch = true) {
    setShowSearchConceptModal(({ show }) => ({
      show: !show,
      isNewSearch,
    }));
  }

  function onConceptSearchSubmit(concept) {
    const id = concept.id || '';
    const name = concept.name || '';
    navigate({
      pathname: `${RELATIVE_PATH}/antibodies`,
      search: `id=${id}&name=${name}`,
    });
  }

  return (
    <div className="antibodies-page text-center">
      <h1 className="text-center antibodies-page__title" id="antibodies-page-top" ref={targetTop}>
        Antibodies for<span> </span>
        {
          concept && concept.id ?
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${RELATIVE_PATH}/concept-details/${concept.id}`}
            >
              {concept.name}
            </a>
            :
            concept ? `${concept.name}` : '...'
        }
        <span
          className="antibodies-page__RiSearchLine"
          onClick={openAntibodyFinder}
        >
          <RiSearchLine size={34} color="#2b5566"/>
        </span>
      </h1>

      <div className="tabs-wrapper">
        <div
          key="patents_plabdab_tab"
          className={classNames({
            'tab-link': true,
            'tab-link_active': isActiveTabPlabdab,
          })}
          onClick={() => setIsActiveTabPlabdab(true)}
        >
          <div className="tab-text">
            Patent and Literature Antibody Database ({patentsCount})
          </div>
        </div>
        <div
          key="patents_wipo_tab"
          className={classNames({
            'tab-link': true,
            'tab-link_active': !isActiveTabPlabdab,
          })}
          onClick={() => setIsActiveTabPlabdab(false)}
        >
          <div className="tab-text">
            WIPO Patents Data ({wiposCount})
          </div>
        </div>
      </div>

      {
        // !loading &&
        <>
          { isActiveTabPlabdab && !loading &&
            <>
              <span className="text-center">The Patent and Literature Antibody Database is a self-updating repository containing over 150,000 paired antibody sequences derived from patents and academic papers.  The data is assembled by the
Oxford Protein Informatics Group. Antibody sequence results are presented as annotated by this source.</span>
              <AntibodiesPlabDabTable/>
              <a
                href="#antibodies-page-top"
                id="antibodies-page-wipos"
                ref={targetWipos}
                onClick={(e) => scrollToElement(e, targetTop, '#antibodies-page-top')}
              >
                Go to Top
              </a>
            </>
          }

          { !isActiveTabPlabdab && !loading &&
            <>
              <span className="text-center pt-15">Results from Patentscope (WIPO) contains patent applications for which antibody sequences documents
are uploaded by the applicant. Antibody sequence results are presented as provided by the applicant.</span>
              <AntibodiesWipoTable/>
              <a
                href="#antibodies-page-top"
                onClick={(e) => scrollToElement(e, targetTop, '#antibodies-page-top')}
              >
                Go to Top
              </a>
            </>
          }
        </>
      }

      <ConceptSearchModal
        isOpen={showSearchConceptModal.show}
        closeCb={toggleSearch}
        onSubmit={onConceptSearchSubmit}
        onSubmitBtnText="Search by concepts"
        action={PortalActions.ANTIBODY_FILTER}
      />

      <Error show={error && !loading} error={error}/>
      <Loader isLoading={loading}/>
    </div>
  );
};

AntibodiesPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: getAntibodiesLoadingSelector(state),
    error: getAntibodiesErrorSelector(state),
    patentsCount: getAntibodiesPatentsCountSelector(state),
    wiposCount: getAntibodiesWiposCountSelector(state),
    concept: getAntibodiesConceptSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadAntibodiesData(data) {
      dispatch(loadAntibodiesDataAction(data));
    },
    setActiveCategories(data) {
      dispatch(setActiveCategoriesAction(data));
    },
    setUseCustomSemTypes(data) {
      dispatch(setUseCustomSemTypesAction(data));
    },
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(AntibodiesPage));
